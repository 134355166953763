<template>
	<div class="ng-content-wrap">
		<div class="ng-block-head ng-block-head-lg">
			<div class="ng-block-head-sub d-print-none">
				<router-link class="back-to" to="/">
					<em class="icon ni ni-chevron-left-c mr-1"></em>
					<span>Dashboard</span>
				</router-link>
			</div>

			<div class="ng-block-between-md g-4">
				<div class="ng-block-head-content">
					<h2 class="ng-block-title fw-normal">
						{{ $route.meta.title }}
					</h2>
					<div class="ng-block-des d-print-none">
						<p>You can find all of your users</p>
					</div>
				</div>
				
				<div class="ng-block-head-content">
					<ul class="ng-block-tools gx-3 d-print-none">
						<li>
							<div class="form-group">
								<input
									type="text"
									class="form-control"
									placeholder="Type to search..."
									v-model="search"
									@keyup="searchRecords"
								/>
							</div>
						</li>
						<li>
							<a
								href="#newItem"
								data-toggle="modal"
								class="btn btn-white btn-dim btn-outline-primary"
							>
								<em class="icon ni ni-plus-c"></em>
								<span>
									<span class="d-none d-sm-inline-block">
										Add
									</span>
									New
								</span>
							</a>
							<div class="modal fade" tabindex="-1" id="newItem">
								<div class="modal-dialog" role="document">
									<form
										class="modal-content"
										@submit.prevent="createRecord"
									>
										<button
											id="hideNewForm"
											class="close"
											data-dismiss="modal"
											aria-label="Close"
										>
											<em
												class="icon ni ni-cross text-white"
											></em>
										</button>

										<div class="modal-header bg-primary">
											<h5
												class="modal-title text-white"
												v-if="record.first_name"
											>
												Adding {{ record.first_name }}
											</h5>
											<h5
												class="modal-title text-white"
												v-else
											>
												Add New User
											</h5>
										</div>

										<div class="modal-body form row">
											<div class="form-group col-md-6">
												<label>First Name</label>
												<input
													class="form-control"
													v-model="record.first_name"
												/>
											</div>

											<div class="form-group col-md-6">
												<label>Last Name</label>
												<input
													class="form-control"
													v-model="record.last_name"
												/>
											</div>

											<div class="form-group col-md-6">
												<label>Email Address</label>
												<input
													class="form-control"
													v-model="record.email"
												/>
											</div>

											<div class="form-group col-md-6">
												<label>Phone Number</label>
												<input
													class="form-control"
													v-model="record.phone"
												/>
											</div>

											<div class="form-group col-md-6">
												<label>Select Role</label>
												<Multiselect
													:options="roles"
													v-model="record.role"
													trackBy="title"
													label="title"
													valueProp="id"
													:searchable="true"
													placeholder="Type to search..."
												/>
											</div>
										</div>

										<div
											class="modal-footer bg-light justify-content-end"
										>
											<button
												class="btn btn-primary float-right"
											>
												<em
													class="icon ni ni-save"
												></em>
												<span>
													Save Details
												</span>
											</button>
										</div>
									</form>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="ng-block">
			<div class="card card-bordered loading" v-if="processing">
				<div class="justify-content-center text-center">
					<div class="spinner-grow text-primary m-3" role="status">
						<span class="sr-only">Processing Data...</span>
					</div>
					<h6 class="mb-4">Processing Data...</h6>
				</div>
			</div>

			<div class="card" v-else>
				<d-table
					:headers="headers"
					:records="records.data"
					@delete="deleteRecord"
				>
					<template #title="scope">
						{{ scope.record.name }}
					</template>
					<template #details="scope">
						<div class="card card-bordered">
							<div class="card-inner">
								<div class="team">
									<div class="team-options">
										<div class="drodown">
											<a
												href="#"
												class="dropdown-toggle btn btn-sm btn-icon btn-trigger"
												data-toggle="dropdown"
												aria-expanded="false"
											>
												<em
													class="icon ni ni-more-h"
												></em>
											</a>
											<div
												class="dropdown-menu dropdown-menu-right"
												style=""
											>
												<ul
													class="link-list-opt no-bdr"
												>
													<li>
														<a
															data-toggle="modal"
															:id="
																'modal-edit-' +
																	scope.record
																		.id
															"
															:href="
																'#modal-update-' +
																	scope.record
																		.id
															"
														>
															<em
																class="icon ni ni-focus"
															></em>
															<span>
																Quick Edit
															</span>
														</a>
													</li>
												</ul>
											</div>
										</div>
									</div>
									<div class="user-card user-card-s2">
										<div class="user-avatar lg bg-primary">
											<img
												:src="scope.record.avatar"
												:alt="scope.record.name"
											/>
											<div
												class="status dot dot-lg dot-success"
											></div>
										</div>
										<div class="user-info">
											<h6>{{ scope.record.name }}</h6>
											<span class="sub-text">
												{{ scope.record.role }}
											</span>
										</div>
									</div>
									<ul class="team-info">
										<li>
											<span>Join Date</span
											><span>24 Jun 2015</span>
										</li>
										<li>
											<span>Contact</span
											><span>{{
												scope.record.phone
											}}</span>
										</li>
										<li>
											<span>Email</span
											><span>{{
												scope.record.email
											}}</span>
										</li>
									</ul>
									<div class="team-view">
										<router-link
											:to="`/users/${scope.record.id}`"
											class="btn btn-block btn-dim btn-primary"
										>
											<span>View/Edit Profile</span>
										</router-link>
									</div>
								</div>
							</div>
						</div>
					</template>
					<template #form="scope">
						<form
							class="form row"
							@submit.prevent="updateRecord(scope.record)"
						>
							<div class="form-group col-md-6">
								<label>First Name</label>
								<input
									class="form-control"
									v-model="scope.record.first_name"
								/>
							</div>

							<div class="form-group col-md-6">
								<label>Last Name</label>
								<input
									class="form-control"
									v-model="scope.record.last_name"
								/>
							</div>

							<div class="form-group col-md-6">
								<label>Email Address</label>
								<input
									class="form-control"
									v-model="scope.record.email"
								/>
							</div>

							<div class="form-group col-md-6">
								<label>Phone Number</label>
								<input
									class="form-control"
									v-model="scope.record.phone"
								/>
							</div>

							<div class="form-group col-md-6">
								<label>Select Role</label>
								<Multiselect
									:options="roles"
									v-model="scope.record.role"
									trackBy="title"
									label="title"
									valueProp="id"
									:searchable="true"
									placeholder="Type to search..."
								/>
							</div>

							<div class="form-group col-md-12">
								<button class="btn btn-primary float-right">
									<em class="icon ni ni-save"></em>
									<span>
										Update {{ scope.record.name }}
									</span>
								</button>
							</div>
						</form>
					</template>
				</d-table>

				<paginate
					v-if="records.data.length"
					:data="records"
					@changed="fetchRecords"
				/>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	import http from "../../../../common/services/http";
	import store from "../../../../common/services/store";
	import { computed, defineComponent, ref, reactive, onMounted } from "vue";

	export default defineComponent({
		setup(props) {
			const headers = {
				name: "Name",
				phone: "Phone Number",
				role: "Role",
			};

			const roles = ref([
				{ id: "customer", title: "Customer" },
				{ id: "accountant", title: "Accountant" },
				{ id: "sales", title: "Salesperson" },
				{ id: "admin", title: "Administrator" },
			]);

			const records = ref({
				data: [],
				first_page: 1,
				last_page: 1,
			});

			const record = reactive({
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				role: { id: "customer", title: "Customer" },
			});

			const search = ref("");

			const processing = ref(false);
			const created = ref(false);
			const updated = ref(false);

			function fetchRecords(page = 1) {
				processing.value = true;
				http.get(`/api/users?page=${page}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function searchRecords(e: any) {
				processing.value = true;
				http.get(`/api/users?s=${search.value}`).then((res) => {
					records.value = res.data;
					processing.value = false;
				});
			}

			function createRecord() {
				http.post("/api/users", record)
					.then((res) => {
						if (res.data.success) {
							created.value = true;
						}
					})
					.finally(() => {
						document.getElementById("hideNewForm")?.click();
						fetchRecords();
					});
			}

			function updateRecord(record: any) {
				record._method = "PUT";
				http.post(`/api/users/${record.id}`, record)
					.then((res) => {
						if (res.data.id) {
							updated.value = true;
							store.dispatch("updateUser", res.data);
							document.getElementById("hideEditForm")?.click();
						}
					})
					.finally(() => {
						fetchRecords();
					});
			}

			function deleteRecord(id: any) {
				http.post(`/api/users/${id}`, { _method: "DELETE" }).then((res) => {
					fetchRecords();
				});
			}

			onMounted(() => {
				fetchRecords();
			});

			return {
				headers,
				fetchRecords,
				processing,
				records,
				createRecord,
				created,
				record,
				updateRecord,
				updated,
				deleteRecord,
				roles,
				search,
				searchRecords,
			};
		},
	});
</script>
